import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

export default function Advocatenkantoor() {
  return (
    <Layout>
      <Seo title="Advocatenkantoor" />
      <div className="container py-8">
        <h1 className="h1">Advocatenkantoor</h1>
        <p className="paragraph">
          Laat een professionele website voor uw advocatenkantoor op maat
          ontwerpen door Thomas' Webdesign.
        </p>
      </div>
    </Layout>
  )
}
